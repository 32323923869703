<template>
	<section class="">
		<article v-for="(response, index) in responses" :key="response.id">
			<h4>RESPONSE #{{ index + 1 }}</h4>
			<figure class="d-flex flex-row align-center justify-space-between">
				<InputTextField v-model="response.score"
					><template #label>Score</template></InputTextField
				>
				<InputTextField
					><template #label>Character Limit</template></InputTextField
				>
			</figure>
		</article>
	</section>
</template>

<script>
export default {
	name: "NewQuestionResponseFreeForm",
	components: {},
	props: {
		responses: {
			type: Array,
			require: true
		}
	},
	computed: {},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style></style>
